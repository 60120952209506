import { Brand } from "@/common/entities/product/Brand";
import { Vats } from "@/common/entities/product/Vats";
import { ProductsRedirects } from "@/common/entities/product/ProductsRedirects";
import { ProductsStocks } from "@/common/entities/product/ProductsStocks";
import { Suppliers } from "@/common/entities/suppliers/Suppliers";
import { Tags } from "@/common/entities/tags/Tags";
import { ProductImage } from "@/common/entities/product/ProductImage";
import { ProductCategory } from "@/common/entities/product/ProductCategory";
import { ProductsPacks, SimilarProducts } from "@/common/entities/product/SimilarProducts";
import { SimilarProductsCategories } from "@/common/entities/product/SimilarCategories";
import { ProductAttribute } from "./Attributes";
import { AwsFile } from "../files/AwsFiles";

export interface Products {
    id: number;
    name: string;
    productCode: string;
    eanCode: string;
    url: string;
    shortDescription: string;
    longDescription: string;
    active: boolean;
    metaTitle: string;
    metaDescription: string;
    metaKeywords: string;
    priceWithoutVat: number;
    priceWithVat: number;
    createdAt: number;
    updatedAt: number;
    productRedirect?: ProductsRedirects;
    productStock: ProductsStocks;
    vat?: Vats;
    brand?: Brand;
    categories?: ProductCategory[];
    configuratorStepProduct?: configuratorProductDetails;
    attributesCharacteristics?: ProductAttribute[];
    suppliers?: Suppliers[];
    tags?: Tags[];
    defaultImage?: DefaultImage;
    productImages?: ProductImage[];
    cover?: ProductImage;
    similarProducts?: SimilarProducts[];
    similarCategories?: SimilarProductsCategories[];
    glass?: boolean;
    height?: number;
    length?: number;
    packedProducts: ProductsPacks[];
    availableQuantity: number;
    specialPriceWithoutVat: number;
    specialPriceWithVat: number;
    type: ProductType;
    productUse?: string;
}

interface configuratorProductDetails {
    id: number;
    isPopular: boolean;
}

export interface ProductFiles {
    data: AwsFile[];
    total: number;
}

export enum ProductType {
    basic = "BASIC",
    pack = "PACK",
    glass = "GLASS",
    door = "DOOR",
}

export enum ProductsListType {
    brand = "brand",
    category = "category",
}

export interface DefaultImage {
    id: number;
    cover: boolean;
    image: AwsFile;
}

export interface ProductLabels {
    warranty?: number;
    pro?: string;
    ecoDesign?: string;
    energyClass?: string;
}
