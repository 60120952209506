"use client";

import React, { ChangeEvent, useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import style from "./searchProducts.module.scss";
import { Products } from "@/common/entities/product/Product";
import { getProducts } from "@/services/ProductsService";
import SearchProductsTitleAndCategory from "@/components/SearchProducts/SearchProductsTitleAndCategory";
import { formatNumberWithDecimals } from "@/common/helpers/util.functions";
import { ImageWrapper } from "@/common/components/Images/ImageWrapper";
import ProductsUtils from "@/common/utils/ProductsUtils";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import useDebounce from "@/common/hooks/useDebounce";

const SearchProducts = () => {
    const [isFocused, setIsFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [products, setProducts] = useState<Products[] | undefined>(undefined);
    const router = useRouter();
    const searchParams = useSearchParams();

    useEffect(() => {
        const query = searchParams.get("query");
        if (query) {
            setSearchTerm(query);
            updateDebounceSearch(query);
        }
    }, [searchParams]);

    const filterProducts = (searchValue: string) => {
        if (searchValue.length >= 3) {
            getProducts({ from: 0, size: 7, search: searchValue })
                .then((response) => {
                    setProducts(response.data);
                })
                .catch(() => setProducts(undefined))
                .finally(() => setIsLoading(false));
        }
    };

    const debouncedFetchSearchResults = useDebounce(filterProducts, 500);

    const updateDebounceSearch = async (searchValue: string) => {
        await debouncedFetchSearchResults(searchValue);
    };

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        const searchValue = e.target.value;
        setSearchTerm(searchValue);
        updateDebounceSearch(searchValue);
    };

    const clearInfo = () => {
        setTimeout(() => {
            setIsFocused(false);
            setProducts(undefined);
            setSearchTerm("");
        }, 120);
    };

    const getDefaultCategoryName = (product: Products) => {
        if (!product.categories || !product.categories.length) {
            return "";
        }

        const defaultCategory = product.categories.find((prodCategory) => prodCategory.default && prodCategory.category);
        if (defaultCategory) {
            return defaultCategory.category.name;
        }

        if (product.categories.length) {
            return product.categories[0].category.name ?? undefined;
        }
        return "";
    };

    const handleSearchSubmit = () => {
        if (searchTerm.trim().length >= 3) {
            router.push(`/search?query=${encodeURIComponent(searchTerm)}`);
        }
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            setIsFocused(false);
            handleSearchSubmit();
        }
    };

    return (
        <div className={style.searchBar}>
            <div className={`${style.searchboxBack} ${isFocused ? style.active : ""}`}></div>
            <InputGroup className={`${isFocused ? style.inputHoverEffect : ""}`}>
                <InputGroup.Text className={style.inputText} onClick={handleSearchSubmit}>
                    <FaSearch />
                </InputGroup.Text>
                <FormControl
                    type="text"
                    value={searchTerm}
                    onChange={onSearchChange}
                    placeholder="Cauta ..."
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => clearInfo()}
                    onKeyDown={onKeyDown}
                />
            </InputGroup>
            {searchTerm && isFocused && (
                <div className={style.searchResult}>
                    {isLoading && (!products || products.length === 0) ? (
                        <div className="ps-3 pt-1">Se Cauta Produse...</div>
                    ) : (
                        <>
                            {products && products.length > 0 ? (
                                products.map((product: Products, index: number) => {
                                    const categoryName = getDefaultCategoryName(product);
                                    const productLink = ProductsUtils.getProductLink(product.url, product.categories);
                                    const categoryLink = productLink.split("/")[1];

                                    return (
                                        <div key={index} className={`d-flex align-items-center ${style.searchItem}`}>
                                            <Link title={product.name} href={productLink}>
                                                <ImageWrapper
                                                    width={80}
                                                    height={80}
                                                    url={product.defaultImage?.image.smallSizeFileName ?? product.defaultImage?.image?.originalFileName}
                                                    title={product.name}
                                                />
                                            </Link>

                                            <SearchProductsTitleAndCategory
                                                title={product.name}
                                                productCode={product.productCode}
                                                productLink={productLink}
                                                categoryName={categoryName}
                                                categoryLink={categoryLink}
                                            />
                                            <div className={style.productPrice}>
                                                {formatNumberWithDecimals(product.specialPriceWithVat ?? product.priceWithVat)} Lei
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="p-4">Nu am gasit produse cu aceasta cautare.</div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchProducts;
